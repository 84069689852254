<template>
  <div class="body">
    <p>
      一、我院可通过官方微信公众号、支付宝生活号、APP自助机提前1-7舔预约挂号（包括知名专家、专家、专科、专病、多学科协作门诊号源），预约时请准确选择预约时间、科室名称、医生姓名等信息。
    </p>
    <p>二、预约挂号及退号截止时间：</p>
    <p class="text-grey">
      ①预约挂号时间：1-7天（如：最早可在本周一早上8:00预约下周一全天的号，最晚可在就诊前一晚23:00前预约次日号源）；
    </p>
    <p class="text-red">
      如需忒好，请于就诊前一晚上23:00前通过原预约渠道退号，挂号费将自动退回到原支付账户，逾期作废。
    </p>
    <p class="text-grey">
      ②预约挂号时间：1-7天（如：最早可在本周一早上8:00预约下周一全天的号，最晚可在就诊前一晚23:00前预约次日号源）；
    </p>
    <p class="text-red">
      如需忒好，请于就诊前一晚上23:00前通过原预约渠道退号，挂号费将自动退回到原支付账户，逾期作废。
    </p>
    <p>
      三、我院可通过官方微信公众号、支付宝生活号、APP自助机提前1-7舔预约挂号（包括知名专家、专家、专科、专病、多学科协作门诊号源），预约时请准确选择预约时间、科室名称、医生姓名等信息。预约时请准确选择预约时间、科室名称、医生姓名等信息。
    </p>
  </div>
</template>

<script>
export default {
  name: "appointNotice",
};
</script>

<style scoped>
.body {
  width: 9rem;
  background-color: #fff;
  margin: 10px auto;
  padding: 10px;
  border-radius: 10px;
  -webkit-box-shadow: #e6e6e6 0px 0px 10px;
  -moz-box-shadow: #e6e6e6 0px 0px 10px;
  box-shadow: #e6e6e6 0px 0px 10px;
  text-align: left;
}
.body p {
  line-height: 0.7rem;
}
.text-grey {
  color: rgba(131, 133, 143, 1);
}
.text-red {
  color: rgba(245, 91, 67, 1);
}
</style>